/**
 * @Description 龙芯应用公社
 * @author Loongson.Quzhenhong
 * @date 21-7-13
*/
<template>
  <div class="cate">
    <div class="option">
      <div class="title">热门软件推荐</div>

    </div>
    <div class="cate_content">
       <div class="cate_content_total">
         <div class="hotre_item" v-for="(item, index) in dataList" @click="itemClick(item.id)">
           <div class="hore_item_top">
             <div class="index">{{index + 1}}</div>
             <div class="ev_item">

               <div class="ev_left">

                 <img :src="item.logoUrl" alt="" width="140" height="128">

               </div>
               <div class="ev_right">
                 <div class="ev_right_top">{{item.name}}</div>
                 <div>推荐星级：{{item.score}}</div>
                 <div>大小：{{item.appSize}}</div>
                 <div>更新时间：{{item.createTime | dataFormat}}</div>
                 <div>类型：{{item.categoryName}}</div>
               </div>
             </div>
           </div>
           <div class="hore_item_bottom" v-html="item.summary"></div>

         </div>
       </div>
    </div>
    <div class="page_">
      <el-pagination
              @current-change="currentChange"
              layout="prev, pager, next"
              :page-size="pageSize"
              :current-page="currentPage"
              :total="total">
      </el-pagination>

    </div>


  </div>
</template>

<script>
  import Item from '@/components/item/Item.vue'
  import {getCateSoft, getHotSoft} from '@/utils/network'
  export default {
    data() {
      return {
        dataList:[],
        currentPage:this.$store.state.isLastPage?parseInt(this.$store.state.lastPage):1,
        pageSize:10,
        total:null
      }
    },
    created() {
      this.getData()
      this.$store.dispatch('isLastPage', {
        isLastPage: false
      })
    },
    methods: {
      currentChange(index) {
        this.currentPage = index
        this.getData()
        window.scrollTo(0, 180);
      },
      itemClick(id) {
        this.$store.dispatch('lastPage', {
          lastPage: this.currentPage
        })
        this.$router.push(`/detail/${id}`)
      },
      async getData() {
        const {data: res} = await getHotSoft({
          page:this.currentPage,
          per_page:this.pageSize,
          sourceType:'web'
        })
        if (res.code !== 200) return this.$message.error('请求失败')
        this.dataList = res.data.vodata
        this.total = res.data.total
        // this.$message.success('获取成功')
      }
    },
    components: {
      Item
    }
  }
</script>

<style lang="less" scoped>
  .cate {
    width: 80%;
    margin: 20px auto ;
    min-height: 670px;
    background-color: #fff;
    overflow: hidden;
    padding: 0 12px;
    .option {
      display: flex;
      //margin: 10px;
      height: 40px;
      line-height: 40px;
      justify-content: flex-start;
      .title {
        padding-left: 14px;
        font-weight: 700;
        font-size: 18px;
      }
    }

    .cate_content {
      width: 90%;
      margin: 0 auto;
      min-height: 545px;
      .cate_content_total {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .hotre_item {
          width: 46%;
          min-width: 338px;
          height: 235px;
          //margin-right: 15px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          margin-bottom: 12px;
          border: solid 1px #c8c8c8;
          &:hover {
            box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
          }
          .hore_item_top {
            display: flex;
            align-items: center;
            .index {
              width: 20px;
              margin-left: 12px;
              height: 20px;
              background-color: red;
              color: #fff;
              font-weight: 700;
              line-height: 20px;
              text-align: center;
            }
            .ev_item {
              padding: 12px;
              width: 400px;
              height: 160px;
              box-sizing: border-box;
              display: flex;
              justify-content: space-around;
              //margin-bottom: 12px;
              .ev_left {
                img {
                  object-fit: cover;
                }
              }
              .ev_right {
                display: flex;
                flex-direction: column;
                justify-content: space-around;;
              }

            }

          }
          .hore_item_bottom {
            font-size: 12px;
            line-height: 20px;
            height: fit-content;
            padding: 0 14px 0 14px;
            display: -webkit-box !important;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp:2;
          }


        }
      }


    }

  }

</style>
